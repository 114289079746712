const hasComponentObj = (ctx) => {
  if (!ctx.component?.name) {
    console.error("There is no component object.");
    return false;
  }

  return true;
};

export default {
  props: {
    componentNameSuffix: String
  },
  created() {
    if (!hasComponentObj(this)) {
      return;
    }

    if (this.componentNameSuffix) {
      this.component.name += this.componentNameSuffix;
    }

    typeof this.component.initialize === "function" && this.component.initialize();
  },
  mounted() {
    if (!hasComponentObj(this)) {
      return;
    }

    if (!this.$el || typeof this.$el.setAttribute !== "function") {
      return;
    }

    if (this.component.checkDuplicatedName && document.querySelector(`[data-component-name=${this.component.name}]`)) {
      console.warn(`이 컴포넌트와 동일한 이름을 가진 컴포넌트가 있습니다(${this.component.name}). 기능이 정상 작동하지 않을 수 있습니다.`);
    }

    this.$el.setAttribute("data-component-name", this.component.name);
    // this.component.name.startsWith("modal") && this.$store.state.observers.modal.observe(this.$el);
  },
  destroyed() {
    if (!hasComponentObj(this)) {
      return;
    }

    for (let action in this.$store.state.listeners) {
      for (let i in this.$store.state.listeners[action]) {
        if (this.component.name === this.$store.state.listeners[action][i].componentName) {
          this.$store.commit("delListener", [this.component.name, action]);
        }
      }
    }

    if (this.component.name.startsWith("modal")) {
      this.$lib.setBodyScroll();
      // this.$store.state.observers.modal.unobserve(this.$el);
    }
  }
};